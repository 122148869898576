/** @format */

import "./SignalInfo.css";
import { useState, useEffect } from "react";

function SignalInfo({ trader, status, Id, asset, timestamp }) {
  const [isActive, setisActive] = useState();
  const [isPending, setisPending] = useState();
  const [isClosed, setisClosed] = useState();
  const date = new Date(timestamp?.replace("Z", "")).toLocaleDateString(
    "en-US",
    {
      weekday: "short",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "numeric",
      second: "numeric",
      hour12: true,
      timeZoneName: "short",
    }
  );

  useEffect(() => {
    if (status === "active") {
      setisActive(!isActive);
      setisPending(isPending);
      setisClosed(isClosed);
    } else if (status === "pending") {
      setisPending(!isPending);
      setisActive(isActive);
      setisClosed(isClosed);
    } else {
      setisClosed(!isClosed);
      setisPending(isPending);
      setisActive(isActive);
    }
  }, []);

  return (
    <div className="SignalInfoDiv">
      <div className="SignalInfo_left">
        <p className="T_name SignalInfo_margin">Trader:{trader}</p>
        <p
          className={`SignalInfo_AssetName SignalInfo_margin 
          ${isActive && "active"}
          ${isPending && "pending"}
          ${isClosed && "closed"}`}
        >
          {asset}
          <sup className="SignalInfo_status">
            {status}
            <span className="dot"></span>
          </sup>
        </p>
      </div>
      <div className="SignalInfo_right ">
        <p className="SignalInfo_margin">{date}</p>
        <p className="SignalInfo_Id SignalInfo_margin">Signal ID : {Id}</p>
      </div>
    </div>
  );
}

export default SignalInfo;
