/** @format */

import React from "react";
import "./Card_bottom.css";

function Card_bottom({ signal }) {
  var status = signal.status;
  var comment = signal.summary;
  var closed = signal.closed_at;
  var excuted = signal.executed_at;
  const timestamp = closed?.replace("Z", "");
  const closed_at = new Date(timestamp).toLocaleDateString("en-US", {
    weekday: "short",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "numeric",
    second: "numeric",
    hour12: true,
    timeZoneName: "short",
  });

  if (closed && excuted) {
    var difference = new Date(closed).getTime() - new Date(excuted).getTime();

    var daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
    difference -= daysDifference * 1000 * 60 * 60 * 24;

    var hoursDifference = Math.floor(difference / 1000 / 60 / 60);
    difference -= hoursDifference * 1000 * 60 * 60;

    var minutesDifference = Math.floor(difference / 1000 / 60);
    difference -= minutesDifference * 1000 * 60;

    var secondsDifference = Math.floor(difference / 1000);
  }

  return (
    <div>
      {(status === "active" || status === "pending") && (
        <div className="bob">
          <p className="Card_Comment_title">Comments</p>
          <p className="Card_Comment_body">{comment}</p>
        </div>
      )}

      {status === "closed" && (
        <div className="Card_MarketSell_Bottom">
          <div>
            <p className="Card_MarketSell_Bottom_title">Closed at</p>
            <p>{closed_at}</p>
          </div>
          <div>
            <p className="Card_MarketSell_Bottom_title">Trade Lifetime</p>
            <p>
              {daysDifference}d {hoursDifference}h {minutesDifference}m{" "}
              {secondsDifference}s
            </p>
          </div>
          {/* <div>
            <p className="Card_MarketSell_Bottom_title">Result</p>
            <p className="Card_MarketSell_Bottom_title Card_MarketSell_Bottom_Red">
              Stop Loss Hit
            </p>
          </div> */}
        </div>
      )}
    </div>
  );
}

export default Card_bottom;
