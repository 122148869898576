/** @format */

import React from "react";
import "./takeProfit_Card.css";

function TakeProfitCard({ type, tp1, tp2, tp3 }) {
  // const length = take_profit.length;

  return (
    <div className="takeProfit_body">
      <p className="TakeProfit_title">Take Profit</p>
      <div className="TakeProfit_sideDivs">
        <div className="sideDiv_one">
          {tp1 !== null && <p className="TakeProfit_sideDivs_price">{tp1}</p>}
          {tp1 === "" ||
            (tp1 === null && <p className="TakeProfit_sideDivs_price">NA</p>)}

          {/* <p className="TakeProfit_smallWords">10 pips</p> */}
        </div>
        <div className="sideDiv_two">
          {tp2 !== "" && tp2 !== null && (
            <p className="TakeProfit_sideDivs_price">{tp2}</p>
          )}
          {tp2 === "" ||
            (tp2 === null && <p className="TakeProfit_sideDivs_price">NA</p>)}
          {/* <p className="TakeProfit_smallWords">20 pips</p> */}
        </div>
        <div className="sideDiv_three">
          {tp3 !== "" && tp3 !== null && (
            <p className="TakeProfit_sideDivs_price">{tp3}</p>
          )}
          {tp3 === "" ||
            (tp3 === null && <p className="TakeProfit_sideDivs_price">NA</p>)}
          {/* <p className="TakeProfit_smallWords">30 pips</p> */}
        </div>
      </div>
    </div>
  );
  // } else if (type === "Market Sell" || type === "Sell Limit") {
  //   return (
  //     <div className="takeProfit_body">
  //       <p className="TakeProfit_title">Take Profit</p>
  //       <div className="TakeProfit_sideDivs">
  //         <div className="sideDiv_one">
  //           {tp1 !== "" && tp1 !== null && (
  //             <p className="TakeProfit_sideDivs_price">{tp1}</p>
  //           )}
  //           {tp1 == "" ||
  //             (tp1 == null && <p className="TakeProfit_sideDivs_price">NA</p>)}

  //           {/* <p className="TakeProfit_smallWords">10 pips</p> */}
  //         </div>
  //         <div className="sideDiv_two">
  //           {tp2 !== "" && tp2 !== null && (
  //             <p className="TakeProfit_sideDivs_price">{tp2}</p>
  //           )}
  //           {tp2 == "" ||
  //             (tp2 == null && <p className="TakeProfit_sideDivs_price">NA</p>)}
  //           {/* <p className="TakeProfit_smallWords">20 pips</p> */}
  //         </div>
  //         <div className="sideDiv_three">
  //           {tp3 !== "" && tp3 !== null && (
  //             <p className="TakeProfit_sideDivs_price">{tp3}</p>
  //           )}
  //           {tp3 == "" ||
  //             (tp3 == null && <p className="TakeProfit_sideDivs_price">NA</p>)}
  //           {/* <p className="TakeProfit_smallWords">30 pips</p> */}
  //         </div>
  //       </div>
  //     </div>
  //   );
  // } else if (type === "Sell Stop") {
  //   return (
  //     <div className="takeProfit_body">
  //       <p className="TakeProfit_title">Take Profit</p>
  //       <div className="TakeProfit_sideDivs TakeProfit_Notfound">
  //         <div className="sideDiv_one">
  //           {tp1 !== "" && tp1 !== null && (
  //             <p className="TakeProfit_sideDivs_price">{tp1}</p>
  //           )}
  //           {tp1 == "" ||
  //             (tp1 == null && <p className="TakeProfit_sideDivs_price">NA</p>)}
  //           {/* <p className="TakeProfit_smallWords">10 pips</p> */}
  //         </div>
  //         <div className="sideDiv_two">
  //           {tp2 !== "" && tp2 !== null && (
  //             <p className="TakeProfit_sideDivs_price">{tp2}</p>
  //           )}
  //           {tp2 == "" ||
  //             (tp2 == null && (
  //               <p className="TakeProfit_sideDivs_price TakeProfit_Notfound_inner">
  //                 NA
  //               </p>
  //             ))}
  //         </div>
  //         <div className="sideDiv_three">
  //           {tp3 !== "" ||
  //             (tp3 !== null && (
  //               <p className="TakeProfit_sideDivs_price">{tp3}</p>
  //             ))}
  //           {tp3 == "" ||
  //             (tp3 == null && (
  //               <p className="TakeProfit_sideDivs_price TakeProfit_Notfound_inner">
  //                 NA
  //               </p>
  //             ))}
  //         </div>
  //       </div>
  //     </div>
  //   );
  // } else if (type === "Buy Limit" || type === "Buy Stop") {
  //   return (
  //     <div className="takeProfit_body_BuyLimit takeProfit_body">
  //       <p className="takeProfit_BuyLimit TakeProfit_title">Take Profit</p>
  //       <div className="TakeProfit_sideDivs">
  //         <div className="sideDiv_one">
  //           {tp1 !== "" && tp1 !== null && (
  //             <p className="takeProfit_BuyLimit TakeProfit_sideDivs_price">
  //               {tp1}
  //             </p>
  //           )}
  //           {tp1 == "" ||
  //             (tp1 == null && <p className="TakeProfit_sideDivs_price">NA</p>)}
  //           {/* <p className="takeProfit_BuyLimit TakeProfit_smallWords">10 pips</p> */}
  //         </div>
  //         <div className="sideDiv_two">
  //           {tp2 !== "" && tp2 !== null && (
  //             <p className="takeProfit_BuyLimit TakeProfit_sideDivs_price">
  //               {tp2}
  //             </p>
  //           )}
  //           {tp2 == "" ||
  //             (tp2 == null && <p className="TakeProfit_sideDivs_price">NA</p>)}
  //           {/* <p className="takeProfit_BuyLimit TakeProfit_smallWords">20 pips</p> */}
  //         </div>
  //         <div className="sideDiv_three" style={{ opacity: "50%" }}>
  //           {tp3 !== "" && tp3 !== null && (
  //             <p className="TakeProfit_sideDivs_price">{tp3}</p>
  //           )}
  //           {tp3 == "" ||
  //             (tp3 !== null && <p className="TakeProfit_sideDivs_price">NA</p>)}
  //           {/* <p className="TakeProfit_smallWords">30 pips</p> */}
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }
}

export default TakeProfitCard;
