/** @format */

import React from "react";
import { useNavigate } from "react-router-dom";
import HeaderImg from "../../../assets/HeaderImg.png";
import "./Header.css";

function Header() {
  const navigate = useNavigate();
  return (
    <div className="Header_Img_Div">
      <img
        className="Header_Img"
        src={HeaderImg}
        alt=""
        onClick={() => navigate("/")}
      />
    </div>
  );
}

export default Header;
