
/** @format */

import './App.css'

import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import PageSwitch from "./PageSwitch";
import { Provider } from 'react-redux';
import {store, persistor} from './redux/store';


// initiate socket connection
const url = process.env.REACT_APP_SOCKET;
export const socket = new WebSocket(url);

function App() {   

  return (
    <Provider store={store}>
    <PersistGate persistor={persistor}>
    <div className='Main'>
      <Router>
        <PageSwitch />
      </Router>
    </div>
    </PersistGate>
    </Provider>
  );
}

export default App;
