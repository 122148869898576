/** @format */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  signals: [],
  signalById: {},
  loading: false,
  error: "",
  signalFollowUp: [],
};

const signalsSlice = createSlice({
  initialState,
  name: "signals",
  reducers: {
    fetchSignalsRquest(state) {
      state.loading = true;
    },
    fetchSignalsSuccess(state, action) {
      state.loading = false;
      state.signals = action.payload;
    },
    fetchSignalsFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    fetchLivePriceRquest(state) {
      state.loading = true;
    },
    fetchLivePriceSuccess(state, action) {
      state.loading = false;
      state.signals = action.payload;
    },
    fetchLivePriceFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    fetchSignalByIdRquest(state) {
      state.loading = true;
    },
    fetchSignalByIdSuccess(state, action) {
      state.loading = false;
      state.signalById = action.payload;
    },
    fetchSignalByIdFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    fetchSignalByIdUpdateRquest(state) {
      state.loading = true;
    },
    fetchSignalByIdUpdateSuccess(state, action) {
      state.loading = false;
      state.signalById = action.payload;
    },
    fetchSignalByIdUpdateFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    fetchSignalFollowUpRquest(state) {
      state.loading = true;
    },
    fetchSignalFollowUpSuccess(state, action) {
      state.loading = false;
      state.signalFollowUp = action.payload;
    },
    fetchSignalFollowUpFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const SignalsActions = signalsSlice.actions;
export default signalsSlice;
