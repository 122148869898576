/** @format */

import React, { useEffect, useState } from "react";
import "./FollowUpCard.css";
import Arrow from "../../assets/Arrow.png";
import { useSelector } from "react-redux";
function FollowUpCard({ signal }) {
  // const dispatch = useDispatch();
  var updates = "";
  const [a, setA] = useState("");
  const [signalFollow, setsignalFollow] = useState([]);
  const signals = useSelector((store) => store.signalsReducer.signalFollowUp);
  useEffect(() => {
    setsignalFollow(signals);
  }, [signals]);

  var timeStamp = signal.timeStamp;
  var up = [];

  const changeUp = (signalUpdate) => {
    up = signalUpdate[0].updates.split(",");
    up = up.filter(function (ewww) {
      if (
        ewww === "" ||
        ewww === "closed_at" ||
        ewww === "summary" ||
        ewww === "description" ||
        ewww === "followup_summary" ||
        ewww === "executed_at" || ewww === "updated_at" || ewww === "is_admin" || ewww === "user_id"
      )
        return false;
      return true;
    });
  };

  return (
    <>
      {signalFollow.map((signalUpdate, index) => {
        let date = new Date(
          signalUpdate[0].timestamp.replace("Z", "")
        ).toLocaleDateString("en-US", {
          weekday: "short",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "numeric",
          second: "numeric",
          hour12: true,
          timeZoneName: "short",
        });

        {
          if (
            signalUpdate[0].updates == "description," || signalUpdate[0].updates == "," ||
            signalUpdate[0].updates == "closed_at," ||
            signalUpdate[0].updates == "summary," ||
            signalUpdate[0].updates == "followup_summary," ||
            signalUpdate[0].updates == "executed_at," || signalUpdate[0].updates == "updated_at," || signalUpdate[0].updates == "is_admin," || signalUpdate[0].updates == "user_id," || signalUpdate[0].updates == ","  || signalUpdate[0].updates == "" 
          ) {
            return;
          } else {
            return (
              <div key={index}>
                {index === 0 && (
                  <>
                  
                    {signalUpdate[0].updates !== "" &&(
                      <div className="followUpCard_Container blueBorder">
                        <div className="followUpCard_UpperDiv">
                          <div className="followUpCard_UpperLeft">
                            {timeStamp}
                            <p className="followUpCard_UpperLeft_UpdateType">
                              {changeUp(signalUpdate)}

                              <p>Updates ({up.join(", ")})</p>
                            </p>
                          </div>
                          <div className="followUpCard_UpperRight">
                            <p>{date}</p>
                          </div>
                        </div>
                        <hr className="followUpCard_HR" />
                        {up.map((singleUpdateString, i) => {
                          return (
                            <div key={i}>
                              {singleUpdateString !== "" &&
                                singleUpdateString !== "description" &&
                                singleUpdateString !== "closed_at" &&
                                singleUpdateString !== "summary" &&
                                singleUpdateString !== "executed_at" && (
                                  <div className="followUpCard_Lower">
                                    <div className="followUpCard_LowerLeft"></div>
                                    <div>
                                      <p className="followUpCard_Lower_oldTPPrice">
                                        {signalUpdate[1][singleUpdateString]
                                          ? signalUpdate[1][singleUpdateString]
                                          : "NA"}
                                      </p>
                                      <p className="followUpCard_Lower_smallWords">
                                        Old {singleUpdateString}
                                      </p>
                                    </div>
                                    <img
                                      src={Arrow}
                                      className="followUpCard_Arrow"
                                    />
                                    <div>
                                      <p className="followUpCard_Lower_newTPPrice">
                                        {(singleUpdateString == "tp1" ||
                                          singleUpdateString == "tp2" ||
                                          singleUpdateString == "tp3" ||
                                          signalUpdate[0][singleUpdateString] ==
                                            "active") && (
                                          <span class="green-color">
                                            {
                                              signalUpdate[0][
                                                singleUpdateString
                                              ]
                                            }
                                          </span>
                                        )}
                                        {(singleUpdateString == "sl_level" ||
                                          signalUpdate[0][singleUpdateString] ==
                                            "closed") && (
                                          <span class="red-color">
                                            {
                                              signalUpdate[0][
                                                singleUpdateString
                                              ]
                                            }
                                          </span>
                                        )}
                                      </p>

                                      <p className="followUpCard_Lower_smallWords">
                                        New {singleUpdateString}
                                      </p>
                                    </div>
                                  </div>
                                )}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </>
                )}
                {index !== 0 && (
                  <>
                    {signalUpdate[0].updates !== "" &&(
                      <div className="followUpCard_Container greyBorder">
                        <div className="followUpCard_UpperDiv">
                          <div className="followUpCard_UpperLeft">
                            {timeStamp}
                            <p className="followUpCard_UpperLeft_UpdateType">
                              {changeUp(signalUpdate)}

                              <p>Updates ({up.join(", ")})</p>
                            </p>
                          </div>
                          <div className="followUpCard_UpperRight">
                            <p>{date}</p>
                          </div>
                        </div>
                        <hr className="followUpCard_HR" />
                        {changeUp(signalUpdate)}
                        {up.map((singleUpdateString, i) => {
                          return (
                            <div key={i}>
                              {singleUpdateString !== "" && (
                                <div className="followUpCard_Lower">
                                  <div className="followUpCard_LowerLeft"></div>
                                  <div>
                                    <p className="followUpCard_Lower_oldTPPrice">
                                      {signalUpdate[1][singleUpdateString]
                                        ? signalUpdate[1][singleUpdateString]
                                        : "NA"}
                                    </p>
                                    <p className="followUpCard_Lower_smallWords">
                                      Old {singleUpdateString}
                                    </p>
                                  </div>
                                  <img
                                    src={Arrow}
                                    className="followUpCard_Arrow"
                                  />
                                  <div>
                                    <p className="followUpCard_Lower_newTPPrice">
                                      {(singleUpdateString == "tp1" ||
                                        singleUpdateString == "tp2" ||
                                        singleUpdateString == "tp3" ||
                                        signalUpdate[0][singleUpdateString] ==
                                          "active") && (
                                        <span class="green-color">
                                          {signalUpdate[0][singleUpdateString]}
                                        </span>
                                      )}
                                      {(singleUpdateString == "sl_level" ||
                                        signalUpdate[0][singleUpdateString] ==
                                          "closed") && (
                                        <span class="red-color">
                                          {signalUpdate[0][singleUpdateString]}
                                        </span>
                                      )}
                                    </p>
                                    <p className="followUpCard_Lower_smallWords">
                                      New {singleUpdateString}
                                    </p>
                                  </div>
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </>
                )}
              </div>
            );
          }
        }
      })}

      {/* {type === "Add Stop Loss" && (
        <div>
          <div className="followUpCard_UpperDiv">
            <div className="followUpCard_UpperLeft">
              {timeStamp}
              <p className="followUpCard_UpperLeft_UpdateType">Add Stop Loss</p>
            </div>
            <div className="followUpCard_UpperRight">
              <p className="followUpCard_Explenation">{comment}</p>
            </div>
          </div>
          <hr className="followUpCard_HR" />
          <div className="followUpCard_Lower">
            <div className="followUpCard_LowerLeft"></div>
            <div>
              <p className="followUpCard_Lower_NewSLPrice">{newSl}</p>
              <p className="followUpCard_Lower_smallWords">New SL</p>
            </div>
          </div>
        </div>
      )}
      {(type === "Close" || type === "Hit") && (
        <div>
          <div className="followUpCard_UpperDiv">
            <div className="followUpCard_UpperLeft">
              {timeStamp}
              <p className="followUpCard_UpperLeft_UpdateType">
                {type === "Close" && <div>Close 25%</div>}
                {type === "Hit" && <div>TP1 Hit</div>}
              </p>
            </div>
            <div className="followUpCard_UpperRight">
              <p className="followUpCard_Explenation">{comment}</p>
            </div>
          </div>
          <hr className="followUpCard_HR" />
          <Card_bottom signal={signal} />
        </div>
      )} */}
    </>
  );
}

export default FollowUpCard;
