/** @format */

import React from "react";
import "./stopLoss_Card.css";

function StopLossCard({ type, stop_loss }) {
  let sl = stop_loss;
  // const length = stop_loss.length;
  // if (length > 0) {
  //   sl = stop_loss[length - 1].sl_level;
  // }

  return (
    <div className="StopLoss_MarketBuy">
      <p className="StopLoss_title">Stop Loss</p>
      <div>
        {sl !== null && <p className="StopLoss_Price">{sl}</p>}
        {sl === null && <p className="StopLoss_Price">NA</p>}

        {/* <div className="StopLoss_innerWords">
            <p>20 pips</p>
            <p style={{ color: "#FF7878" }}>3%</p>
          </div> */}
      </div>
    </div>
  );
  // } else if (type === "Market Sell" || type === "Sell Limit") {
  //   return (
  //     <div className="StopLoss_MarketBuy StopLoss_MarketSell">
  //       <p className="StopLossMarketSell_title">Stop Loss</p>
  //       <div>
  //         {sl !== "" && <p className="StopLoss_Price">{sl}</p>}
  //         {sl === "" && <p className="StopLoss_Price">NA</p>}
  //         {/* <div className="StopLoss_innerWords">
  //           <p>20 pips</p>
  //           <p style={{ color: "#FF7878" }}>3%</p>
  //         </div> */}
  //       </div>
  //     </div>
  //   );
  // } else if (type === "Sell Stop") {
  //   return (
  //     <div className="StopLoss_MarketBuy">
  //       <p className="StopLoss_title">Stop Loss</p>
  //       <div>
  //         {sl !== "" && <p className="StopLoss_Price">{sl}</p>}
  //         {sl === "" && <p className="StopLoss_Price">NA</p>}
  //         {/* <div className="StopLoss_innerWords">
  //           <p>20 pips</p>
  //           <p style={{ color: "#FF7878" }}>3%</p>
  //         </div> */}
  //       </div>
  //     </div>
  //   );
  // } else if (type === "Buy Limit" || type === "Buy Stop") {
  //   return (
  //     <div className="StopLoss_MarketBuy">
  //       <p className="StopLoss_title">Stop Loss</p>
  //       <div>
  //         {sl !== "" && <p className="StopLoss_Price">{sl}</p>}
  //         {sl === "" && <p className="StopLoss_Price">NA</p>}
  //         {/* <div className="StopLoss_innerWords">
  //           <p>20 pips</p>
  //           <p style={{ color: "#FF7878" }}>3%</p>
  //         </div> */}
  //       </div>
  //     </div>
  //   );
}

export default StopLossCard;
