/** @format */

import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import signalsSlice from "./signals/signalsReducer";
import storage from 'redux-persist/lib/storage' 
import { persistStore, persistReducer } from 'redux-persist'


const persistConfig = {
  key: 'root',
  storage,
}
 


const reducer = combineReducers({
  signalsReducer: signalsSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, reducer)


const middleware = [thunk];
const store  = createStore(persistedReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);
let persistor = persistStore(store)
export {store, persistor} 