/** @format */

import React from "react";
import "./ordertype_Card.css";
import MarketBuyLogo from "../../assets/MarketBuyLogo.png";
import MarketSellLogo from "../../assets/MarketSellLogo.png";
import SellLimitLogo from "../../assets/SellLimitLogo.png";
import BuyLimitLogo from "../../assets/BuyLimitLogo.png";

function OrderTypeCard({ type, market_price, excuted_at, tradeType }) {
  let excution_date;
  if (excuted_at !== null) {
    var a = excuted_at?.replace("Z", "");
    excution_date = new Date(a).toLocaleDateString("en-US", {
      weekday: "short",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
      timeZoneName: "short",
    });
  }
  if (type === "Market Buy") {
    return (
      <div className="smallcard-body">
        <div className="SmallCard_market">
          <div className="Market_Top">
            <div className="MarketTop_left">
              <img src={MarketBuyLogo} alt={MarketBuyLogo} />
              <p className="MarketBuyTop_Name">{type}</p>
            </div>
            <div>
              <p className="MarketTop_price">{market_price}</p>
            </div>
          </div>
          <hr className="OrderType_Underline" />
          <div className="Market_Body">
            <div>
              <p className="Market_Body_left">Signal Information</p>
            </div>
            <div className="Market_Body_2">
              <p className="Market_Body_left">Executed at</p>
              {excuted_at && <p>{excution_date}</p>}
              {!excuted_at && <p>Still Not executed </p>}
            </div>
            <div className="Market_Body_2">
              <p className="Market_Body_left">Trade Type:</p>
              <p>{tradeType}</p>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (type === "Market Sell" || type === "Sell Limit") {
    return (
      <div className="smallcard-body">
        <div className="SmallCard_market">
          <div className="Market_Top">
            <div className="MarketTop_left">
              <img src={MarketSellLogo} alt={MarketSellLogo} />
              <p className="MarketSellTop_Name">{type}</p>
            </div>
            <div>
              <p className="MarketTop_price">{market_price}</p>
            </div>
          </div>
          <hr className="OrderType_Underline" />
          <div className="Market_Body">
            <div>
              <p className="Market_Body_left">Signal Information</p>
            </div>
            <div className="Market_Body_2">
              <p className="Market_Body_left">Executed at</p>

              {excuted_at && <p>{excution_date}</p>}
              {!excuted_at && <p>Still Not Executed</p>}
            </div>
            <div className="Market_Body_2">
              <p className="Market_Body_left">Trade Type:</p>
              <p>{tradeType}</p>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (type === "Sell Stop") {
    return (
      <div className="smallcard-body">
        <div className="SmallCard_market">
          <div className="Market_Top">
            <div className="MarketTop_left">
              <img src={SellLimitLogo} alt={SellLimitLogo} />
              <p className="SellStopTop_Name">{type}</p>
            </div>
            <div>
              <p className="MarketTop_price">{market_price}</p>
            </div>
          </div>
          <hr className="OrderType_Underline" />
          <div className="Market_Body">
            <div>
              <p className="Market_Body_left">Signal Information</p>
            </div>
            <div className="Market_Body_2">
              <p className="Market_Body_left">Executed at</p>
              {excuted_at && <p>{excution_date}</p>}
              {!excuted_at && <p>Still Not Executed</p>}
            </div>
            <div className="Market_Body_2">
              <p className="Market_Body_left">Trade Type:</p>
              <p>{tradeType}</p>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (type === "Buy Limit" || type === "Buy Stop") {
    return (
      <div className="smallcard-body">
        <div className="SmallCard_market">
          <div className="Market_Top">
            <div className="MarketTop_left">
              <img src={BuyLimitLogo} alt={BuyLimitLogo} />
              <p className="MarketBuyLimitTop_Name">{type}</p>
            </div>
            <div>
              <p className="MarketTop_price">{market_price}</p>
            </div>
          </div>
          <hr className="OrderType_Underline" />
          <div className="Market_Body">
            <div>
              <p className="Market_Body_left">Signal Information</p>
            </div>
            <div className="Market_Body_2">
              <p className="Market_Body_left">Executed at</p>
              {excuted_at && <p>{excution_date}</p>}
              {!excuted_at && <p>NA</p>}
            </div>
            <div className="Market_Body_2">
              <p className="Market_Body_left">Trade Type:</p>
              <p>{tradeType}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OrderTypeCard;
