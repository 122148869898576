/** @format */

import React, { useEffect, useState } from "react";
import "./Feed.css";
import Card from "../../components/card/Card";
import { useSelector, useDispatch } from "react-redux";
import { getSignals } from "../../redux/signals/signalsActions";
import { TablePagination } from "@mui/material";
import { socket } from "../../App";

function Feed() {
  // const [countActiveSignals, setcountActiveSignals] = useState(0);
  const [count, setCount] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  socket.onmessage = function (e) {
    let event = e.data;
    
    if (event == "signal_created" || event == "signal_updated") {
      dispatch(getSignals());
      // window.setTimeout(function () {
      //     window.location.reload();
      //   }, 100);
      window.location.reload();

    }
  };

  const dispatch = useDispatch();
  const { signals } = useSelector((store) => store.signalsReducer);

  useEffect(() => {
    dispatch(getSignals());
  }, []);

  useEffect(() => {
    setCount(signals.filter((signal) => signal.status == "active"));
  }, [signals]);

  return (
    <div className="App">
      <div className="App_top">
        <p>Signals Feed</p>
        <p>
          Status:{" "}
          <span style={{ color: "#78FFBC" }}>
            {count.length} active signals
          </span>
        </p>
      </div>
      {/*
        username: trader-name
        excuted_at: time that the signal becomes active
        updated_at: time we update a signal
        timestamp : date of creation of signal
        market-price: market price
      */}
      {signals
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((signal, index) => {
          return (
            <div key={index}>
              <Card signal={signal} />
            </div>
          );
        })}

      <TablePagination
        className="pagCol"
        count={signals.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[]}
      />
    </div>
  );
}

export default Feed;
