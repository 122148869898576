/** @format */

import React, { useEffect, useState } from "react";
import OrderTypeCard from "../orderType_Card/OrderTypeCard";
import "./card.css";
import StopLossCard from "../stopLoss_Card/StopLossCard";
import TakeProfitCard from "../takeProfit_Card/TakeProfitCard";
import SignalInfo from "../SignalInfo/SignalInfo";
import { Link } from "react-router-dom";
import Card_bottom from "../Card_bottom/Card_bottom";
import { useSelector, useDispatch } from "react-redux";
import {
  SignalFollowUp,
  SignalUpdateFollowUp,
} from "../../redux/signals/signalsActions";
import { useNavigate } from "react-router-dom";
import { socket } from "../../App";

function Card({ signal }) {
  const type = signal.trade_type;
  const followUp = signal.followUp;
  const username = signal.User?.username;
  const status = signal.status;
  const b2b_signal_id = signal.b2b_signal_id;
  const asset_name = signal.asset_name;
  const summary = signal.summary;
  const market_type = signal.order_type;
  const market_price = signal.market_price;
  const excuted_at = signal.executed_at;
  const tp1 = signal.tp1;
  const tp2 = signal.tp2;
  const tp3 = signal.tp3;
  const sl = signal.sl_level;
  const closed_at = signal.closed_at;
  const timestamp = signal.timestamp.replace("Z", "");
  const [clicked, setClicked] = useState(false);

  const date1 = new Date(excuted_at);
  const date2 = new Date(closed_at);
  const diffTime = Math.abs(date2 - date1);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    async function bob(){
      if (clicked) {
        await dispatch(SignalFollowUp(b2b_signal_id));
        await dispatch(SignalUpdateFollowUp(b2b_signal_id));
        navigate("/individualSig")
      }
    }
    bob()
    
  }, [clicked]);

  return (
    <div className="Card_body">
      <div>
        <SignalInfo
          trader={username}
          status={status}
          Id={b2b_signal_id}
          asset={asset_name}
          summary={summary}
          market_price={market_price}
          timestamp={timestamp}
        />
      </div>

      <hr style={{ opacity: "0.5", backgroundColor: "white" }} />
      <div className="card_smallerCards">
        <div className="left-card">
          <OrderTypeCard
            type={market_type}
            market_price={market_price}
            excuted_at={excuted_at}
            tradeType={type}
          />
        </div>
        <div className="right-card">
          <StopLossCard type={market_type} stop_loss={sl} />
          <TakeProfitCard type={market_type} tp1={tp1} tp2={tp2} tp3={tp3} />
        </div>
      </div>
      <Card_bottom signal={signal} />

      <div
        className="Card_moreinfo"
        onClick={() => {
          setClicked(true);
        }}
      >
        more information &gt;
      </div>
    </div>
  );
}

export default Card;
