/** @format */

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import SignalInfo from "../SignalInfo/SignalInfo";
import OrderTypeCard from "../orderType_Card/OrderTypeCard";
import StopLossCard from "../stopLoss_Card/StopLossCard";
import TakeProfitCard from "../takeProfit_Card/TakeProfitCard";
import FollowUpCard from "../FollowUpCard/FollowUpCard";
import "./Followup.css";
import { useNavigate } from "react-router-dom";
import ChartUnavailable from "../../assets/ChartUnavailable.png";
import { SignalFollowUp } from "../../redux/signals/signalsActions";
import { SignalUpdateFollowUp } from "../../redux/signals/signalsActions";
import { socket } from "../../App";

function Followup() {
    const [signalFollowUpInfo, setSignalFollowUpInfo] = useState("");
    const [imageLoaded, setImageLoaded] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);

    const SigFollUp = useSelector((store) => store.signalsReducer.signalById);
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const handleImageError = (e) => {
        e.currentTarget.src = ChartUnavailable;
        e.currentTarget.style.cursor = "default";
        setImageLoaded(false);
    };

    // listener for keydown ESC then close image modal
    document.onkeydown = (e) => {
        if (e.key == "Escape") {
            setModalOpen(false);
        }
    };

    socket.onmessage = function (e) {
        let event = e.data;

        if (event == "signal_updated") {
            navigate("/");
            // console.log("signal updated");
        }
    };

    // useEffect(() => {
    //   // console.log("Loading...");
    //   if (localStorage.getItem("signal_id")) {
    //     dispatch(SignalFollowUp(localStorage.getItem("signal_id")));
    //   }
    // }, [signalFollowUpInfo]);

    useEffect(() => {
        setSignalFollowUpInfo(SigFollUp);
    }, []);

    useEffect(() => {
        if (Object.keys(signalFollowUpInfo).length === 0) {
            navigate("/");
        }
    }, [signalFollowUpInfo]);

    if (signalFollowUpInfo !== "") {
        // chart image
        var image =
            process.env.REACT_APP_API +
            "/signals/" +
            localStorage.getItem("signal_id").replace("#", "%23") +
            ".jpeg";

        return (
            <div className="followUp_Container">
                                <button style={{backgroundColor : "inherit", color:"white", opacity:"0.5", position:"absolute",top:"4rem",left:"5%",cursor:"pointer"}} onClick={()=>navigate("/")}>&lt; Go Back</button>

                {signalFollowUpInfo && (
                    <>
                        <div>
                            <SignalInfo
                                trader={signalFollowUpInfo.User.username}
                                status={signalFollowUpInfo.status}
                                Id={signalFollowUpInfo.b2b_signal_id}
                                asset={signalFollowUpInfo.asset_name}
                                timestamp={signalFollowUpInfo.timestamp}
                            />
                        </div>

                        <div className="followUp_body">
                            <div className="followUp_Left">
                                <OrderTypeCard
                                    type={signalFollowUpInfo.order_type}
                                    excuted_at={signalFollowUpInfo.executed_at}
                                    market_price={
                                        signalFollowUpInfo.market_price
                                    }
                                    tradeType={signalFollowUpInfo.trade_type}
                                />
                                <StopLossCard
                                    type="Sell Stop"
                                    stop_loss={signalFollowUpInfo.sl_level}
                                />
                                <TakeProfitCard
                                    type="Sell Stop"
                                    tp1={signalFollowUpInfo.tp1}
                                    tp2={signalFollowUpInfo.tp2}
                                    tp3={signalFollowUpInfo.tp3}
                                />

                                <div className="summary_container">
                                    <div>
                                        <img
                                            className="image_chart"
                                            src={image}
                                            onError={handleImageError}
                                            onClick={() => setModalOpen(true)}
                                        ></img>
                                        {modalOpen && imageLoaded && (
                                            <div id="modal" className="modal">
                                                <span
                                                    onClick={() =>
                                                        setModalOpen(false)
                                                    }
                                                    className="close"
                                                >
                                                    &times;
                                                </span>
                                                <img
                                                    src={image}
                                                    className="modal-content"
                                                    id="chart"
                                                />
                                                <div id="caption"></div>
                                            </div>
                                        )}
                                    </div>
                                    <p className="summary">
                                        {SigFollUp.summary === null && (
                                            <p>Summary not found</p>
                                        )}
                                        {SigFollUp.summary !== null &&
                                            SigFollUp.summary}
                                    </p>
                                </div>
                            </div>
                            <div className="followUp_Right">
                                <p>Recent Updates</p>
                                <FollowUpCard signal={signalFollowUpInfo} />
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    } else {
        setSignalFollowUpInfo(SigFollUp);
    }
}

export default Followup;
