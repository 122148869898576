/** @format */

import React, { Suspense, useLayoutEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Header from "./components/AssetComponents/Header/Header";
import Followup from "./components/Follow up/Followup";
import Feed from "./components/Feed/Feed";

function PageSwitch() {
  return (
    <>
    
      <Header />
      <Routes>
      <Route path="/" element={<Feed />} />
      <Route path="individualSig" element={<Followup />} />
        
      </Routes>
      </>
   
  );
}

export default PageSwitch;
