/** @format */
import axios from "axios";
import { SignalsActions } from "./signalsReducer";

export const getSignals = () => async (dispatch) => {
    try {
        dispatch(SignalsActions.fetchSignalsRquest());
        const response = await axios.get(
            process.env.REACT_APP_API + "/signals/"
        );
        dispatch(SignalsActions.fetchSignalsSuccess(response.data.signals));
    } catch (e) {
        dispatch(SignalsActions.fetchSignalsFail(e.response));
    }
};

export const getLivePrice = (assetName) => async (dispatch) => {
    try {
        dispatch(SignalsActions.fetchLivePriceRquest());
        const response = await axios.get(
            process.env.REACT_APP_API + "/assets/live/" + assetName
        );
        dispatch(SignalsActions.fetchLivePriceSuccess(response.data.results));
    } catch (e) {
        dispatch(SignalsActions.fetchSignalsFail(e.response));
    }
};
export const SignalFollowUp = (signalId) => async (dispatch) => {
    try {
        localStorage.setItem("signal_id", signalId);
        const newSignal = signalId.replace("#", "%23");
        dispatch(SignalsActions.fetchSignalByIdRquest());
        // console.log("a3333333333333333333333333333333333")
        //FIXME the route to get the followup
        const response = await axios.get(
            process.env.REACT_APP_API + "/signals/get/" + newSignal
        );
        // console.log(response)
        dispatch(SignalsActions.fetchSignalByIdSuccess(response.data.signal));
    } catch (e) {
        dispatch(SignalsActions.fetchSignalByIdFail(e.response));
    }
};

export const SignalUpdateFollowUp = (signalId) => async (dispatch) => {
    return new Promise(async (resolve, reject) => {
        try {
            // console.log("sig follow up update");
            const newSignal = signalId.replace("#", "%23");
            dispatch(SignalsActions.fetchSignalFollowUpRquest());
            const response = await axios.get(
                process.env.REACT_APP_API + "/trade_follow_up/get/" + newSignal
            );
            // console.log("rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr",response)
            dispatch(
                SignalsActions.fetchSignalFollowUpSuccess(
                    response.data.follow_up
                )
            );
            resolve(response.data.follow_up);
        } catch (error) {
            dispatch(SignalsActions.fetchSignalFollowUpFail(error.response));
            // console.log("error");
        }
    });
};
